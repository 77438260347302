* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Roboto-Light.ttf");
}
